import React, { useState,useEffect } from 'react'
import '../../../src/App.css'
import '../forms/Form.css'
import { TextFieldStyled, TextFieldSelect } from './Form'

import { useInputValues, withInputValues } from '../../contexts/inputContext'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { BASE_URL } from '../../constants/constants'
import { ForkRight } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { v4 as uuidv4 } from 'uuid'
const Config = () => {
  const { inputValues, setInputValues } = useInputValues()
  const [navItems, setNavItems] = useState(
    inputValues.sideBar.navItems.map((item, index) => ({
      id: uuidv4(),
      title: item.title,
      href: item.href,
      yaml_reference: item.yaml_reference,
      composite: [],
    }))
  )
  const [showCompositeChildren, setShowCompositeChildren] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [deleteModuleIndex, setDeleteModuleIndex] = useState(null)
  const [moduleOpen, setModuleOpen] = useState(false)
  const handleDeleteModuleOpen = (index) => {
    setDeleteModuleIndex(index)
    setModuleOpen(true)
  }

  const handleDeleteModuleClose = () => {
    setModuleOpen(false)
  }

  const handleDeleteModule = () => {
    const updatedModules = inputValues.modules.filter(
      (_, index) => index !== deleteModuleIndex
    )
    setInputValues({
      ...inputValues,
      modules: updatedModules,
    })
    setModuleOpen(false)
  }
  const handleCompositeButtonClick = () => {
    setShowCompositeChildren(true)
  }
  const compositeNavItems = Object.entries(
    navItems.reduce((acc, item) => {
      acc[item.yaml_reference] = acc[item.yaml_reference] || []
      acc[item.yaml_reference].push(item)
      return acc
    }, {})
  )

  // const handleNavItemChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...inputValues.sideBar.navItems];
  //   list[index][name] = value;
  //   setInputValues({
  //     ...inputValues,
  //     sideBar: {
  //       navItems: list,
  //     },
  //   });
  // };
  // useEffect(() => {
  //   if (inputValues?.merged_yamls?.navItems) {
  //     setNavItems(inputValues.merged_yamls.navItems);
  //   }
  // }, [inputValues]);

  const handleInputChangeNav = (index, field) => (e) => {
    const { name, value } = e.target
    const updatedNavItems = [...navItems]
    updatedNavItems[index] = {
      ...updatedNavItems[index],
      [name]: value,
    }
    setNavItems(updatedNavItems)
  }
  const handleMultiSelectChange = (id) => (event) => {
    const {
      target: { value },
    } = event

    const newValues = typeof value === 'string' ? value.split(',') : value

    const updatedNavItems = [...navItems]
    const navItemIndex = updatedNavItems.findIndex((item) => item.id === id)

    if (navItemIndex === -1) {
      console.error(`Nav item with id ${id} not found`)
      return
    }

    const updatedComposite = newValues
      .map((title) => {
        const selectedNavItem = inputValues.merged_yamls?.navItems.find(
          (item) => item.title === title
        )
        if (selectedNavItem) {
          return {
            title: selectedNavItem.title,
            href: selectedNavItem.href,
            yaml_reference: selectedNavItem.yaml_reference,
          }
        }
        return null
      })
      .filter((item) => item !== null)

    updatedNavItems[navItemIndex] = {
      ...updatedNavItems[navItemIndex],
      composite: updatedComposite,
    }

    setNavItems(updatedNavItems)

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      sideBar: {
        ...prevInputValues.sideBar,
        navItems: updatedNavItems,
      },
    }))
  }

  const handleDeleteNavItem = (title) => {
    const updatedNavItems = navItems.filter((item) => item.title !== title)
      const moduleToDelete = inputValues.modules.find(
      (module) => module.name === title
    )
  
    const isModuleInComposite = navItems.some((item) =>
      item.composite?.some(
        (compositeItem) => compositeItem.title === moduleToDelete?.name
      )
    )
  
    if (isModuleInComposite) {
      setNavItems(updatedNavItems)
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        sideBar: {
          ...prevInputValues.sideBar,
          navItems: updatedNavItems,
        },
      }))
      setOpen(false)
      return
    }
  
    const updatedModules = inputValues.modules.filter(
      (module) => module.name !== title
    )
      setNavItems(updatedNavItems)
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      sideBar: {
        ...prevInputValues.sideBar,
        navItems: updatedNavItems,
      },
      modules: updatedModules,
    }))
  
    setOpen(false)
  }
  
  
  const {
    _id,
    createdBy,
    isYamlResponse,
    table_type,
    updatedAt,
    yamlUrl,
    ...values
  } = inputValues
  const handleInputChange = (e) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [e.target.name]: e.target.value,
    }))
  }

  const [open, setOpen] = React.useState(false)

  const location = useLocation()
  const { applicationType } = location.state || {}
  const handleClickOpen = (index) => {
    setDeleteIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleAddNavItem = () => {
    setInputValues({
      ...inputValues,
      sideBar: {
        navItems: [...inputValues.sideBar.navItems, { title: '', href: '' }],
      },
    })
  }

  const handleRemoveNavItem = (index) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      sideBar: {
        ...prevInputValues.sideBar,
        navItems: prevInputValues.sideBar.navItems.filter(
          (_, i) => i !== index
        ),
      },
    }))
    setOpen(false)
  }
  const handleShowSideBarChange = (e) => {
    setInputValues({
      ...inputValues,
      appBar: {
        ...inputValues.appBar,
        showSideBar: e.target.checked,
      },
    })
  }

  const handleAuthSwitchChange = (event) => {
    setInputValues({
      ...inputValues,
      auth: {
        isAuthenticate: event.target.checked,
      },
    })
  }
  // const handleAppTitleChange = (e) => {
  //   setInputValues({
  //     ...inputValues,
  //     appBar: {
  //       ...inputValues.appBar,
  //       title: e.target.value,
  //     },
  //   })
  // }
  const [error, setError] = useState('')
  const handleChangeSideBar = (e) => {
    setInputValues({
      ...inputValues,
      appBar: { ...inputValues.appBar, sideBarWidth: Number(e.target.value) },
    })
    if (
      handleChangeSideBar === '' ||
      (parseInt(e.target.value) >= 60 && parseInt(e.target.value) <= 250)
    ) {
      setError('')
    } else {
      setError('Please enter a number between 60 and 250.')
    }
  }

  const getCompositeNavItems = (navItems) => {
    return navItems.map((item) => {
      const compositeNavItems = navItems.filter((nav) =>
        item.composite.includes(nav.title)
      )
      return {
        title: item.title,
        href: item.href,
        composite: compositeNavItems,
      }
    })
  }

  const editApplicationData = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }

      const response = await fetch(
        `${BASE_URL}/applications/${inputValues._id}`,
        requestOptions
      )

      if (response.ok) {
        const response = await fetch(
          `${BASE_URL}/applications/${inputValues._id}`
        )
        toast.success('Data updated successfully')
        const data = await response.json()
        if (response.ok) {
          setInputValues({
            ...data?.data,
            isYamlResponse: true,
            orgName: inputValues.orgName,
          })
        } else {
          throw new Error(`Error: ${response.status} - ${response.statusText}`)
        }
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      console.error('An error occurred:', error)
      toast.error(`An error occurred: ${error.message}`)
    }
  }
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {' '}
        <Button
          sx={{
            padding: '8px 40px',
            backgroundColor: '#808080',
            color: '#fff',
            cursor: 'pointer',
            height: '45px',
            flexGrow: '0',
            fontFamily: 'Ubuntu, sans-serif',

            fontSize: '18px',
            fontWeight: '700',
            borderRadius: '30px',
            lineHeight: 'normal',
            marginBottom: '1rem',
            textTransform: 'none',
          }}
          variant='contained'
          color='primary'
          onClick={editApplicationData}
          className='Update-btn'
        >
          Update
        </Button>
        <ToastContainer />
      </Box>{' '}
      <div sx={{ minHeight: '60vh' }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid item xs={4}>
            <TextFieldStyled
              fullWidth
              label='App Name'
              variant='outlined'
              value={inputValues.appName}
              name='appName'
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={inputValues.appBar.showSideBar}
                  onChange={handleShowSideBarChange}
                />
              }
              label='Show LHS Menu?'
              labelPlacement='start'
            />
          </Grid>
          {inputValues.appBar.showSideBar && (
            <Grid item xs={4}>
              <TextFieldStyled
                label='LHS Menu Width'
                onChange={handleChangeSideBar}
                value={
                  inputValues.appBar.sideBarWidth
                    ? inputValues.appBar.sideBarWidth
                    : 'NA'
                }
                name='sideBarWidth'
                type='number'
                min={60}
                max={250}
              />
              {error && (
                <p style={{ color: 'red', marginTop: '-15px' }}>{error}</p>
              )}
            </Grid>
          )}
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Accordion sx={{ width: '75%', maxWidth: '500px' }}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>App Base URL</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mt: 2 }}>
                {Array.isArray(inputValues.baseUrl) ? (
                  inputValues.baseUrl.map((data, index) => (
                    <Box
                      key={index}
                      sx={{ width: '100%', maxWidth: '450px', mb: 2 }}
                    >
                      <TextFieldStyled
                        fullWidth
                        label={data.appName || 'App Name'}
                        variant="outlined"
                        name={`baseUrl-${index}`}
                        value={data.base_url || ''}
                        onChange={(e) => {
                          const newBaseUrl = [...inputValues.baseUrl];
                          newBaseUrl[index].base_url = e.target.value;
                          setInputValues((prevValues) => ({
                            ...prevValues,
                            baseUrl: newBaseUrl,
                          }));
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box sx={{ width: '100%', maxWidth: '450px', mb: 2 }}>
                    <TextFieldStyled
                      fullWidth
                      label={inputValues.appName || 'App Name'}
                      variant="outlined"
                      name="baseUrl"
                      value={inputValues.baseUrl || ''}
                      onChange={(e) => {
                        const newBaseUrl = { ...inputValues.baseUrl, base_url: e.target.value };
                        setInputValues((prevValues) => ({
                          ...prevValues,
                          baseUrl: newBaseUrl,
                        }));
                      }}
                    />
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            // alignItems: "flex-start",
            marginBottom: "0.75rem",
          }}
        > */}
        {/* <Grid item xs={8}>
            {applicationType !== "mfe application" && (
              <FormControlLabel
                control={
                  <Switch
                    checked={inputValues.auth.isAuthenticate}
                    onChange={handleAuthSwitchChange}
                  />
                }
                label="Enable Authentication?"
                labelPlacement="start"
              />
            )}
          </Grid> */}
        {inputValues.appBar.showSideBar && (
          <Box
            sx={{
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              sx={{
                width: '201px',
                borderRadius: '30px',
                padding: '8px 40px',
                backgroundColor: '#1C242C',
                color: '#fff',
                cursor: 'pointer',
                height: '45px',

                fontFamily: 'Ubuntu, sans-serif',
                marginBottom: '1rem',
                fontSize: '18px',
                fontWeight: '700',
                lineHeight: 'normal',
                textTransform: 'none',
              }}
              variant='contained'
              color='primary'
              onClick={handleCompositeButtonClick}
            >
              <AddIcon aria-label='add' />
              Composite
            </Button>

            <Button
              sx={{
                marginLeft: '10px',
                width: '201px',
                borderRadius: '30px',
                padding: '8px 40px',
                backgroundColor: '#1C242C',
                color: '#fff',
                cursor: 'pointer',
                height: '45px',
                flexGrow: '0',
                fontFamily: 'Ubuntu, sans-serif',
                marginBottom: '1rem',
                fontSize: '18px',
                fontWeight: '700',
                lineHeight: 'normal',
                textTransform: 'none',
              }}
              variant='contained'
              color='primary'
              onClick={handleAddNavItem}
            >
              <AddIcon aria-label='add' />
              Menu Item
            </Button>
          </Box>
        )}

        {inputValues.appBar.showSideBar && (
          <div>
            {compositeNavItems.map(([yamlRef, items]) => (
              <Accordion key={yamlRef}>
                <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                  <Typography>{yamlRef}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {items.map((item) => (
                    <Grid
                      container
                      spacing={2}
                      key={item.id}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={3} sx={{ marginTop: '1rem' }}>
                        <TextFieldStyled
                          label={`Menu Item Title`}
                          name='title'
                          variant='outlined'
                          fullWidth
                          value={item.title}
                          onChange={handleInputChangeNav(item.id, 'title')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFieldStyled
                          label={`Menu Item Link`}
                          variant='outlined'
                          name='href'
                          value={item.href}
                          onChange={handleInputChangeNav(item.id, 'href')}
                          fullWidth
                          sx={{ marginTop: '1rem' }}
                        />
                      </Grid>

                      {showCompositeChildren && (
                        <Grid item xs={3}>
                          <FormControl
                            sx={{ width: '100%', marginTop: '1rem' }}
                          >
                            <InputLabel id='demo-multiple-checkbox-label'>
                              Select Composite Children
                            </InputLabel>
                            <TextFieldSelect
                              labelId='demo-multiple-checkbox-label'
                              id='demo-multiple-checkbox'
                              multiple
                              name='composite'
                              value={item.composite.map((child) => child.title)}
                              onChange={handleMultiSelectChange(item.id)}
                              input={
                                <OutlinedInput label='Select Composite Children' />
                              }
                              renderValue={(selected) => selected.join(', ')}
                              fullWidth
                            >
                              {inputValues.merged_yamls?.navItems
                                .filter((op) => op.title !== item.title)
                                .map((option) => (
                                  <MenuItem
                                    key={option.title}
                                    value={option.title}
                                  >
                                    <Checkbox
                                      checked={item.composite.some(
                                        (child) => child.title === option.title
                                      )}
                                    />
                                    <ListItemText primary={`${option.title}`} />
                                  </MenuItem>
                                ))}
                            </TextFieldSelect>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={1}>
                        <React.Fragment>
                          <IconButton className='delete-icon'>
                            <DeleteIcon
                              variant='contained'
                              aria-label='delete'
                              onClick={() => handleClickOpen(item.title)} // Adjust for index
                            />
                          </IconButton>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby='alert-dialog-title'
                            aria-describedby='alert-dialog-description'
                            slotProps={{
                              backdrop: {
                                style: {
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                },
                              },
                            }}
                            sx={{
                              '& .MuiPaper-root': {
                                backdropFilter: 'blur(5px)',
                                boxShadow: 'none',
                              },
                            }}
                          >
                            <DialogTitle id='alert-dialog-title'>
                              {'Delete'}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id='alert-dialog-description'>
                                Are you sure you want to permanently delete?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  handleDeleteNavItem(deleteIndex)
                                }}
                                autoFocus
                              >
                                Yes
                              </Button>
                              <Button onClick={handleClose}>No</Button>
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Config
